@mixin icon-style() {
  font-size: 3rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  
  &:hover, &:focus {
    background-color: #0000000a;
  }
}

@mixin transition15() {
  transition: (all 0.15s ease-out 0s);
}


@mixin fadeIn() {
  animation: 'fadeIn' 1s ease-in;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
  to {
    opacity: 1;
  }
   
  }
   
}
