
$primary: #3C006C;
$primary-darken: #230046;
$primary-lighten: #975dc0;
$primary-darken-2: #5B0D9A;
$primary-lighten-2: #7D12BF;

$accent: #FE8601;
$accent-darken: #FD7B00;
$btn-accent: #FE7A00;
$light: #fff;

$dark: #2C2D34;
$dark-2: #333333;
$darkgray: #797979;
$darkgray-2: #707070;
