@import '../../../assets/styles/variables';

.ant-menu.menu {
    border: transparent;
    
    .ant-menu-item {
        border: transparent;
    }

    .link {
      font-family: 'Montserrat', sans-serif;
      font-size: 0.7rem;
      color: $darkgray;

      &:hover, &:focus {
        color: $primary-lighten-2;
      }
  
     }
  
     .active {
      font-weight: bold;
      color: $primary-lighten-2;
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
        border: none !important;
        border-radius: 25px;
        background: transparent !important;

        .link {
            color: $primary-lighten-2;
        }
    }
}