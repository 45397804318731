@import './variables';
@import './mixin';
 
@font-face {
  font-family: 'Montserrat';
  src: 
  url('../fonts/Montserrat/Montserrat-Medium.ttf'),
  url('../fonts/Montserrat/Montserrat-Black.ttf'),
  url('../fonts/Montserrat/Montserrat-ExtraLight.ttf'),
  url('../fonts/Montserrat/Montserrat-Light.ttf'),
  url('../fonts/Montserrat/Montserrat-Medium.ttf'),
  url('../fonts/Montserrat/Montserrat-Regular.ttf'),
  url('../fonts/Montserrat/Montserrat-Thin.ttf'),
}

@font-face {
  font-family: 'Varela Round';
  src:  url('../fonts/Varela_Round/VarelaRound-Regular.ttf');
}

* , *:before, *:after {
  box-sizing: inherit;
}
   
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Open Sans", "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  box-sizing: border-box;
 scroll-behavior: smooth;


  font-size: 16px;
  
  @media screen and (min-width: 900px) {
      font-size: 18px;
  } 

  @media screen and (min-width: 1200px) {
      font-size: 20px;
  }
  
}

.ant-input {
 
  &:hover, &:focus {
    border: 1px solid #3C006C !important;
    box-shadow: 0 1px 1px 0.5px #3C006C;
    transition: 0.3s ease-in-out !important;
  }
}


button {

  &.btn-primary {
    background-color: #FE7A00;
    border-color: #FE7A00;
    color: $light;
    &:hover,
    &:active,
    &:focus {
      background-color: #FE7A00;
      border-color: #FE7A00;
      color: $light;
      opacity: 0.9;
    }
    &:disabled,
    &:disabled:hover {
      background-color: #FE7A00;
      border-color: #FE7A00;
      color: $light;
      opacity: 0.5;
    }
  }

  &.btn-secondary {

    background-color: #3C006C;
    border-color: #3C006C;
    color: #fff;
    &:hover,
    &:active,
    &:focus {
      background-color: #3C006C;
      border-color: #3C006C;
      color: #fff;
      opacity: 0.9;
    }
    &:disabled,
    &:disabled:hover {
      background-color: #3C006C;
      border-color:#3C006C;
      color: #fff;
      opacity: 0.5;
    }
  }
}