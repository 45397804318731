@import "../assets/styles/variables";
@import "../assets/styles/mixin";

.layout {
  background-color: #fff !important;
 
   .content {
    background-color: #fff !important;
    // padding: 0 50px;
    min-height: calc(100vh - 70px);
    animation: fadeIn 1s ease-in;
    overflow: hidden;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @keyframes fadeLeft {
//   0% {
//     opacity: 0;
//     transform: translateX(-20px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }


.not-found {
  height: 100vh;
  text-align: center;

  svg {
   font-size: 1rem;
  }
}
