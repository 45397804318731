@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

header.header {
  background-color:  $light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: 70px;
    @media screen and (max-width: 575px) {
      padding: 0 2em;
    }

  > .logo {

    width: 140px;
    height: 38px;
    background-image: url("../../../assets/images/logos/logo.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
     
  }

  .burger-menu {
     @include icon-style();
  }

}